@use "Content/scss/bs5variables";
@use "Content/scss/colors";
@use "Content/scss/sizes";

.pagination {
    .page-item {
        width: 32px;
        height: 32px;
        text-align: center;
        .page-link {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition:
                color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: sizes.$border-radius !important;
            &.nav-arrow-btn {
                background: transparent;
                border: none;
                color: colors.$border-color-light-var !important;
                border-radius: 50% !important;
                box-shadow: none;
                i {
                    top: 1px;
                }
                &:hover,
                &:focus {
                    background-color: colors.$panel-bg-1-var;
                    color: colors.$text-color-var !important;
                }
            }
        }
        &.disabled {
            pointer-events: none;
            .page-link {
                background: transparent;
                border: none;
                &.nav-arrow-btn {
                    opacity: 0.5;
                }
            }
        }
        &:hover {
            filter: colors.$hover-filter-var;
        }
        &.active {
            .page-link {
                background-color: colors.$primary-var;
                color: colors.$text-emphasis-var;
            }
        }
    }
}
