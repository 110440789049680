@use "Content/scss/bs5variables";

.node-set {
    display: inline-flex;
    gap: bs5variables.$gutter-sm;
    padding: bs5variables.$gutter-xxs bs5variables.$gutter-sm;
    border-left: 4px solid;
    border-radius: bs5variables.$border-radius;
    background-color: bs5variables.$panel-header-bg-var;

    .node-set-list {
        display: flex;
        flex-direction: row;
        gap: bs5variables.$gutter-xs;
        flex-wrap: wrap;
    }

    .node-set-separator {
        border-right: bs5variables.$border-width solid bs5variables.$border-color-light-var;
    }

    .node-set-label {
        i {
            transform: scale(1.2);
            margin-right: 0.2em;
        }

        .node-set-label-name {
            font-weight: 800;
        }
        label {
            padding: bs5variables.$gutter-xxs;
            &:hover {
                filter: bs5variables.$hover-filter-var;
            }
        }
    }

    .node-set-item {
        i {
            margin-right: 0.2em;
            display: inline-block;
        }

        .node-set-item-name {
            position: relative;
            font-weight: 800;
            color: bs5variables.$text-emphasis-var;
        }

        label {
            padding: bs5variables.$gutter-xxs;
            &:hover {
                filter: bs5variables.$hover-filter-var;
            }
        }
    }
    .form-label {
        margin-bottom: 0;
        cursor: pointer;
    }
    .form-check {
        margin-top: bs5variables.$gutter-xxs;
        min-height: 0;
    }
}
