@use "Content/scss/sizes";

.code {
    .code-classes {
        height: unset;
        padding: sizes.$gutter-xs;
        background-color: var(--well-bg);
        border-radius: sizes.$gutter-xxs;
    }
}
