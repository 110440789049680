@font-face {
    font-family: "icomoon";
    font-display: block;
    src: url("/studio/app.icomoon.fe4bb74d661afcb65df96860bb2f5191.eot?#iefix") format("embedded-opentype"),
url("/studio/app.icomoon.563930e2e385e2b37bfc2e922d9680b2.woff") format("woff"),
url("/studio/app.icomoon.09ed301c9366d740100751f1005f03e9.woff2") format("woff2"),
url("/studio/app.icomoon.1e7f646279f63406b11e9b8281b43ba7.ttf") format("truetype"),
url("/studio/app.icomoon.efcc06da7977032b47142c8ab4d18d79.svg#icomoon") format("svg");
}

.icon {
    line-height: 1;
}

.icon:before {
    font-family: icomoon !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.icon-lock:before {
    content: "\f101";
}
.icon-addon-lock:after {
    content: "\f101";
}
.icon-node-leader:before {
    content: "\f102";
}
.icon-addon-node-leader:after {
    content: "\f102";
}
.icon-placeholder:before {
    content: "\f103";
}
.icon-addon-placeholder:after {
    content: "\f103";
}
.icon-dbgroup-member:before {
    content: "\f104";
}
.icon-addon-dbgroup-member:after {
    content: "\f104";
}
.icon-dbgroup-promotable:before {
    content: "\f105";
}
.icon-addon-dbgroup-promotable:after {
    content: "\f105";
}
.icon-dbgroup-rehab:before {
    content: "\f106";
}
.icon-addon-dbgroup-rehab:after {
    content: "\f106";
}
.icon-server-wide-backup:before {
    content: "\f107";
}
.icon-addon-server-wide-backup:after {
    content: "\f107";
}
.icon-backup2:before {
    content: "\f108";
}
.icon-addon-backup2:after {
    content: "\f108";
}
.icon-ravendb-etl:before {
    content: "\f109";
}
.icon-addon-ravendb-etl:after {
    content: "\f109";
}
.icon-external-replication:before {
    content: "\f10a";
}
.icon-addon-external-replication:after {
    content: "\f10a";
}
.icon-sql-etl:before {
    content: "\f10b";
}
.icon-addon-sql-etl:after {
    content: "\f10b";
}
.icon-olap-etl:before {
    content: "\f10c";
}
.icon-addon-olap-etl:after {
    content: "\f10c";
}
.icon-elastic-search-etl:before {
    content: "\f10d";
}
.icon-addon-elastic-search-etl:after {
    content: "\f10d";
}
.icon-subscription:before {
    content: "\f10e";
}
.icon-addon-subscription:after {
    content: "\f10e";
}
.icon-pull-replication-hub:before {
    content: "\f10f";
}
.icon-addon-pull-replication-hub:after {
    content: "\f10f";
}
.icon-pull-replication-agent:before {
    content: "\f110";
}
.icon-addon-pull-replication-agent:after {
    content: "\f110";
}
.icon-copy-to-clipboard:before {
    content: "\f111";
}
.icon-addon-copy-to-clipboard:after {
    content: "\f111";
}
.icon-unfold:before {
    content: "\f112";
}
.icon-addon-unfold:after {
    content: "\f112";
}
.icon-database:before {
    content: "\f113";
}
.icon-addon-database:after {
    content: "\f113";
}
.icon-arrow-down:before {
    content: "\f114";
}
.icon-addon-arrow-down:after {
    content: "\f114";
}
.icon-arrow-right:before {
    content: "\f115";
}
.icon-addon-arrow-right:after {
    content: "\f115";
}
.icon-edit:before {
    content: "\f116";
}
.icon-addon-edit:after {
    content: "\f116";
}
.icon-cancel:before {
    content: "\f117";
}
.icon-addon-cancel:after {
    content: "\f117";
}
.icon-warning:before {
    content: "\f118";
}
.icon-addon-warning:after {
    content: "\f118";
}
.icon-default:before {
    content: "\f119";
}
.icon-addon-default:after {
    content: "\f119";
}
.icon-server:before {
    content: "\f11a";
}
.icon-addon-server:after {
    content: "\f11a";
}
.icon-check:before {
    content: "\f11b";
}
.icon-addon-check:after {
    content: "\f11b";
}
.icon-document:before {
    content: "\f11c";
}
.icon-addon-document:after {
    content: "\f11c";
}
.icon-trash:before {
    content: "\f11d";
}
.icon-addon-trash:after {
    content: "\f11d";
}
.icon-info:before {
    content: "\f11e";
}
.icon-addon-info:after {
    content: "\f11e";
}
.icon-danger:before {
    content: "\f11f";
}
.icon-addon-danger:after {
    content: "\f11f";
}
.icon-connection-lost:before {
    content: "\f120";
}
.icon-addon-connection-lost:after {
    content: "\f120";
}
.icon-empty-set:before {
    content: "\f121";
}
.icon-addon-empty-set:after {
    content: "\f121";
}
.icon-disabled:before {
    content: "\f122";
}
.icon-addon-disabled:after {
    content: "\f122";
}
.icon-conflicts:before {
    content: "\f123";
}
.icon-addon-conflicts:after {
    content: "\f123";
}
.icon-waiting:before {
    content: "\f124";
}
.icon-addon-waiting:after {
    content: "\f124";
}
.icon-cluster-member:before {
    content: "\f125";
}
.icon-addon-cluster-member:after {
    content: "\f125";
}
.icon-cluster-promotable:before {
    content: "\f126";
}
.icon-addon-cluster-promotable:after {
    content: "\f126";
}
.icon-cluster-watcher:before {
    content: "\f127";
}
.icon-addon-cluster-watcher:after {
    content: "\f127";
}
.icon-arrow-up:before {
    content: "\f128";
}
.icon-addon-arrow-up:after {
    content: "\f128";
}
.icon-kafka-etl:before {
    content: "\f129";
}
.icon-addon-kafka-etl:after {
    content: "\f129";
}
.icon-rabbitmq-etl:before {
    content: "\f130";
}
.icon-addon-rabbitmq-etl:after {
    content: "\f130";
}
.icon-kafka-sink:before {
    content: "\f131";
}
.icon-addon-kafka-sink:after {
    content: "\f131";
}
.icon-rabbitmq-sink:before {
    content: "\f132";
}
.icon-addon-rabbitmq-sink:after {
    content: "\f132";
}
.icon-preview:before {
    content: "\f133";
}
.icon-addon-preview:after {
    content: "\f133";
}
.icon-azure-queue-storage-etl:before {
    content: "\f134";
}
.icon-addon-azure-queue-storage-etl:after {
    content: "\f134";
}
.icon-corax-include-null-match:before {
    content: "\f140";
}
.icon-addon-corax-include-null-match:after {
    content: "\f140";
}
.icon-corax-fallback:before {
    content: "\f141";
}
.icon-addon-corax-fallback:after {
    content: "\f141";
}
.icon-corax-all-entries-match:before {
    content: "\f142";
}
.icon-addon-corax-all-entries-match:after {
    content: "\f142";
}
.icon-corax-boosting-match:before {
    content: "\f143";
}
.icon-addon-corax-boosting-match:after {
    content: "\f143";
}
.icon-corax-forward:before {
    content: "\f144";
}
.icon-addon-corax-forward:after {
    content: "\f144";
}
.icon-corax-memoization-match:before {
    content: "\f145";
}
.icon-addon-corax-memoization-match:after {
    content: "\f145";
}
.icon-corax-multi-term-match:before {
    content: "\f146";
}
.icon-addon-corax-multi-term-match:after {
    content: "\f146";
}
.icon-corax-operator-and:before {
    content: "\f147";
}
.icon-addon-corax-operator-and:after {
    content: "\f147";
}
.icon-corax-operator-andnot:before {
    content: "\f148";
}
.icon-addon-corax-operator-andnot:after {
    content: "\f148";
}
.icon-corax-operator-or:before {
    content: "\f149";
}
.icon-addon-corax-operator-or:after {
    content: "\f149";
}
.icon-corax-phrase-query:before {
    content: "\f14a";
}
.icon-addon-corax-phrase-query:after {
    content: "\f14a";
}
.icon-corax-sorting-match:before {
    content: "\f14b";
}
.icon-addon-corax-sorting-match:after {
    content: "\f14b";
}
.icon-corax-spatial-match:before {
    content: "\f14c";
}
.icon-addon-corax-spatial-match:after {
    content: "\f14c";
}
.icon-corax-term-match:before {
    content: "\f14d";
}
.icon-addon-corax-term-match:after {
    content: "\f14d";
}
.icon-corax-unary-match:before {
    content: "\f14e";
}
.icon-addon-corax-unary-match:after {
    content: "\f14e";
}
.icon-corax-backward:before {
    content: "\f14f";
}
.icon-addon-corax-backward:after {
    content: "\f14f";
}
.icon-corax-sort-az:before {
    content: "\f150";
}
.icon-addon-corax-sort-az:after {
    content: "\f150";
}
.icon-corax-sort-za:before {
    content: "\f151";
}
.icon-addon-corax-sort-za:after {
    content: "\f151";
}
.icon-close:before {
    content: "\f162";
}
.icon-addon-close:after {
    content: "\f162";
}
.icon-about:before {
    content: "\f12a";
}
.icon-addon-about:after {
    content: "\f12a";
}
.icon-accept:before {
    content: "\f12b";
}
.icon-addon-accept:after {
    content: "\f12b";
}
.icon-access-admin:before {
    content: "\f12c";
}
.icon-addon-access-admin:after {
    content: "\f12c";
}
.icon-access-read-write:before {
    content: "\f12d";
}
.icon-addon-access-read-write:after {
    content: "\f12d";
}
.icon-access-read:before {
    content: "\f12e";
}
.icon-addon-access-read:after {
    content: "\f12e";
}
.icon-additional-assemblies:before {
    content: "\f12f";
}
.icon-addon-additional-assemblies:after {
    content: "\f12f";
}
.icon-additional-sources:before {
    content: "\f135";
}
.icon-addon-additional-sources:after {
    content: "\f135";
}
.icon-admin-logs:before {
    content: "\f136";
}
.icon-addon-admin-logs:after {
    content: "\f136";
}
.icon-administrator-js-console:before {
    content: "\f137";
}
.icon-addon-administrator-js-console:after {
    content: "\f137";
}
.icon-alert:before {
    content: "\f138";
}
.icon-addon-alert:after {
    content: "\f138";
}
.icon-alerts:before {
    content: "\f139";
}
.icon-addon-alerts:after {
    content: "\f139";
}
.icon-api-keys:before {
    content: "\f13a";
}
.icon-addon-api-keys:after {
    content: "\f13a";
}
.icon-apple:before {
    content: "\f13b";
}
.icon-addon-apple:after {
    content: "\f13b";
}
.icon-arrow-dashed:before {
    content: "\f13c";
}
.icon-addon-arrow-dashed:after {
    content: "\f13c";
}
.icon-arrow-filled-down:before {
    content: "\f13d";
}
.icon-addon-arrow-filled-down:after {
    content: "\f13d";
}
.icon-arrow-filled-left:before {
    content: "\f13e";
}
.icon-addon-arrow-filled-left:after {
    content: "\f13e";
}
.icon-arrow-filled-right:before {
    content: "\f13f";
}
.icon-addon-arrow-filled-right:after {
    content: "\f13f";
}
.icon-arrow-filled-up:before {
    content: "\f152";
}
.icon-addon-arrow-filled-up:after {
    content: "\f152";
}
.icon-arrow-left:before {
    content: "\f153";
}
.icon-addon-arrow-left:after {
    content: "\f153";
}
.icon-arrow-thin-bottom:before {
    content: "\f154";
}
.icon-addon-arrow-thin-bottom:after {
    content: "\f154";
}
.icon-arrow-thin-left:before {
    content: "\f155";
}
.icon-addon-arrow-thin-left:after {
    content: "\f155";
}
.icon-arrow-thin-right:before {
    content: "\f156";
}
.icon-addon-arrow-thin-right:after {
    content: "\f156";
}
.icon-arrow-thin-top:before {
    content: "\f157";
}
.icon-addon-arrow-thin-top:after {
    content: "\f157";
}
.icon-arrow:before {
    content: "\f158";
}
.icon-addon-arrow:after {
    content: "\f158";
}
.icon-attachment:before {
    content: "\f159";
}
.icon-addon-attachment:after {
    content: "\f159";
}
.icon-average:before {
    content: "\f15a";
}
.icon-addon-average:after {
    content: "\f15a";
}
.icon-aws:before {
    content: "\f15b";
}
.icon-addon-aws:after {
    content: "\f15b";
}
.icon-azure-queue-storage:before {
    content: "\f15c";
}
.icon-addon-azure-queue-storage:after {
    content: "\f15c";
}
.icon-azure:before {
    content: "\f15d";
}
.icon-addon-azure:after {
    content: "\f15d";
}
.icon-backup-history:before {
    content: "\f15e";
}
.icon-addon-backup-history:after {
    content: "\f15e";
}
.icon-backup-snapshot:before {
    content: "\f15f";
}
.icon-addon-backup-snapshot:after {
    content: "\f15f";
}
.icon-backup:before {
    content: "\f160";
}
.icon-addon-backup:after {
    content: "\f160";
}
.icon-backups:before {
    content: "\f161";
}
.icon-addon-backups:after {
    content: "\f161";
}
.icon-book:before {
    content: "\f163";
}
.icon-addon-book:after {
    content: "\f163";
}
.icon-bug:before {
    content: "\f164";
}
.icon-addon-bug:after {
    content: "\f164";
}
.icon-calendar:before {
    content: "\f165";
}
.icon-addon-calendar:after {
    content: "\f165";
}
.icon-certificate:before {
    content: "\f166";
}
.icon-addon-certificate:after {
    content: "\f166";
}
.icon-changes:before {
    content: "\f167";
}
.icon-addon-changes:after {
    content: "\f167";
}
.icon-checkmark:before {
    content: "\f168";
}
.icon-addon-checkmark:after {
    content: "\f168";
}
.icon-chevron-down:before {
    content: "\f169";
}
.icon-addon-chevron-down:after {
    content: "\f169";
}
.icon-chevron-left:before {
    content: "\f16a";
}
.icon-addon-chevron-left:after {
    content: "\f16a";
}
.icon-chevron-right:before {
    content: "\f16b";
}
.icon-addon-chevron-right:after {
    content: "\f16b";
}
.icon-chevron-up:before {
    content: "\f16c";
}
.icon-addon-chevron-up:after {
    content: "\f16c";
}
.icon-chrome:before {
    content: "\f16d";
}
.icon-addon-chrome:after {
    content: "\f16d";
}
.icon-circle-filled:before {
    content: "\f16e";
}
.icon-addon-circle-filled:after {
    content: "\f16e";
}
.icon-circle:before {
    content: "\f16f";
}
.icon-addon-circle:after {
    content: "\f16f";
}
.icon-clean:before {
    content: "\f170";
}
.icon-addon-clean:after {
    content: "\f170";
}
.icon-clear:before {
    content: "\f171";
}
.icon-addon-clear:after {
    content: "\f171";
}
.icon-clearance:before {
    content: "\f172";
}
.icon-addon-clearance:after {
    content: "\f172";
}
.icon-client-configuration:before {
    content: "\f173";
}
.icon-addon-client-configuration:after {
    content: "\f173";
}
.icon-client:before {
    content: "\f174";
}
.icon-addon-client:after {
    content: "\f174";
}
.icon-clock:before {
    content: "\f175";
}
.icon-addon-clock:after {
    content: "\f175";
}
.icon-clone:before {
    content: "\f176";
}
.icon-addon-clone:after {
    content: "\f176";
}
.icon-cloud:before {
    content: "\f177";
}
.icon-addon-cloud:after {
    content: "\f177";
}
.icon-cluster-dashboard:before {
    content: "\f178";
}
.icon-addon-cluster-dashboard:after {
    content: "\f178";
}
.icon-cluster-log:before {
    content: "\f179";
}
.icon-addon-cluster-log:after {
    content: "\f179";
}
.icon-cluster-node:before {
    content: "\f17a";
}
.icon-addon-cluster-node:after {
    content: "\f17a";
}
.icon-cluster-rehab:before {
    content: "\f17b";
}
.icon-addon-cluster-rehab:after {
    content: "\f17b";
}
.icon-cluster-wide-database-settings:before {
    content: "\f17c";
}
.icon-addon-cluster-wide-database-settings:after {
    content: "\f17c";
}
.icon-cluster:before {
    content: "\f17d";
}
.icon-addon-cluster:after {
    content: "\f17d";
}
.icon-cmp-xchg:before {
    content: "\f17e";
}
.icon-addon-cmp-xchg:after {
    content: "\f17e";
}
.icon-code:before {
    content: "\f17f";
}
.icon-addon-code:after {
    content: "\f17f";
}
.icon-coffee:before {
    content: "\f180";
}
.icon-addon-coffee:after {
    content: "\f180";
}
.icon-collapse-horizontal:before {
    content: "\f181";
}
.icon-addon-collapse-horizontal:after {
    content: "\f181";
}
.icon-collapse-vertical:before {
    content: "\f182";
}
.icon-addon-collapse-vertical:after {
    content: "\f182";
}
.icon-collapse:before {
    content: "\f183";
}
.icon-addon-collapse:after {
    content: "\f183";
}
.icon-collections-storage:before {
    content: "\f184";
}
.icon-addon-collections-storage:after {
    content: "\f184";
}
.icon-compact:before {
    content: "\f185";
}
.icon-addon-compact:after {
    content: "\f185";
}
.icon-config:before {
    content: "\f186";
}
.icon-addon-config:after {
    content: "\f186";
}
.icon-conflicts-resolution:before {
    content: "\f187";
}
.icon-addon-conflicts-resolution:after {
    content: "\f187";
}
.icon-connected:before {
    content: "\f188";
}
.icon-addon-connected:after {
    content: "\f188";
}
.icon-copy:before {
    content: "\f189";
}
.icon-addon-copy:after {
    content: "\f189";
}
.icon-crane:before {
    content: "\f18a";
}
.icon-addon-crane:after {
    content: "\f18a";
}
.icon-create-sample-data:before {
    content: "\f18b";
}
.icon-addon-create-sample-data:after {
    content: "\f18b";
}
.icon-crown:before {
    content: "\f18c";
}
.icon-addon-crown:after {
    content: "\f18c";
}
.icon-csharp-logo:before {
    content: "\f18d";
}
.icon-addon-csharp-logo:after {
    content: "\f18d";
}
.icon-csharp:before {
    content: "\f18e";
}
.icon-addon-csharp:after {
    content: "\f18e";
}
.icon-csv-export:before {
    content: "\f18f";
}
.icon-addon-csv-export:after {
    content: "\f18f";
}
.icon-csv-import:before {
    content: "\f190";
}
.icon-addon-csv-import:after {
    content: "\f190";
}
.icon-custom-analyzers:before {
    content: "\f191";
}
.icon-addon-custom-analyzers:after {
    content: "\f191";
}
.icon-custom-functions:before {
    content: "\f192";
}
.icon-addon-custom-functions:after {
    content: "\f192";
}
.icon-custom-sorters:before {
    content: "\f193";
}
.icon-addon-custom-sorters:after {
    content: "\f193";
}
.icon-cut:before {
    content: "\f194";
}
.icon-addon-cut:after {
    content: "\f194";
}
.icon-dashboard:before {
    content: "\f195";
}
.icon-addon-dashboard:after {
    content: "\f195";
}
.icon-data-archival:before {
    content: "\f196";
}
.icon-addon-data-archival:after {
    content: "\f196";
}
.icon-data-subscriptions:before {
    content: "\f197";
}
.icon-addon-data-subscriptions:after {
    content: "\f197";
}
.icon-database-client-configuration:before {
    content: "\f198";
}
.icon-addon-database-client-configuration:after {
    content: "\f198";
}
.icon-database-cutout:before {
    content: "\f199";
}
.icon-addon-database-cutout:after {
    content: "\f199";
}
.icon-database-home:before {
    content: "\f19a";
}
.icon-addon-database-home:after {
    content: "\f19a";
}
.icon-database-id:before {
    content: "\f19b";
}
.icon-addon-database-id:after {
    content: "\f19b";
}
.icon-database-record:before {
    content: "\f19c";
}
.icon-addon-database-record:after {
    content: "\f19c";
}
.icon-database-settings:before {
    content: "\f19d";
}
.icon-addon-database-settings:after {
    content: "\f19d";
}
.icon-database-studio-configuration:before {
    content: "\f19e";
}
.icon-addon-database-studio-configuration:after {
    content: "\f19e";
}
.icon-dbgroup-watcher:before {
    content: "\f19f";
}
.icon-addon-dbgroup-watcher:after {
    content: "\f19f";
}
.icon-dbgroup:before {
    content: "\f1a0";
}
.icon-addon-dbgroup:after {
    content: "\f1a0";
}
.icon-debug-advanced:before {
    content: "\f1a1";
}
.icon-addon-debug-advanced:after {
    content: "\f1a1";
}
.icon-debug:before {
    content: "\f1a2";
}
.icon-addon-debug:after {
    content: "\f1a2";
}
.icon-debug2:before {
    content: "\f1a3";
}
.icon-addon-debug2:after {
    content: "\f1a3";
}
.icon-demote:before {
    content: "\f1a4";
}
.icon-addon-demote:after {
    content: "\f1a4";
}
.icon-details:before {
    content: "\f1a5";
}
.icon-addon-details:after {
    content: "\f1a5";
}
.icon-diff:before {
    content: "\f1a6";
}
.icon-addon-diff:after {
    content: "\f1a6";
}
.icon-disable:before {
    content: "\f1a7";
}
.icon-addon-disable:after {
    content: "\f1a7";
}
.icon-disconnect:before {
    content: "\f1a8";
}
.icon-addon-disconnect:after {
    content: "\f1a8";
}
.icon-disconnected:before {
    content: "\f1a9";
}
.icon-addon-disconnected:after {
    content: "\f1a9";
}
.icon-disk-io-viewer:before {
    content: "\f1aa";
}
.icon-addon-disk-io-viewer:after {
    content: "\f1aa";
}
.icon-disk-support:before {
    content: "\f1ab";
}
.icon-addon-disk-support:after {
    content: "\f1ab";
}
.icon-dismiss-all:before {
    content: "\f1ac";
}
.icon-addon-dismiss-all:after {
    content: "\f1ac";
}
.icon-document-cutout:before {
    content: "\f1ad";
}
.icon-addon-document-cutout:after {
    content: "\f1ad";
}
.icon-document-expiration:before {
    content: "\f1ae";
}
.icon-addon-document-expiration:after {
    content: "\f1ae";
}
.icon-document-group:before {
    content: "\f1af";
}
.icon-addon-document-group:after {
    content: "\f1af";
}
.icon-document-metadata:before {
    content: "\f1b0";
}
.icon-addon-document-metadata:after {
    content: "\f1b0";
}
.icon-document2:before {
    content: "\f1b1";
}
.icon-addon-document2:after {
    content: "\f1b1";
}
.icon-documents-compression:before {
    content: "\f1b2";
}
.icon-addon-documents-compression:after {
    content: "\f1b2";
}
.icon-documents-query:before {
    content: "\f1b3";
}
.icon-addon-documents-query:after {
    content: "\f1b3";
}
.icon-documents:before {
    content: "\f1b4";
}
.icon-addon-documents:after {
    content: "\f1b4";
}
.icon-download:before {
    content: "\f1b5";
}
.icon-addon-download:after {
    content: "\f1b5";
}
.icon-drive:before {
    content: "\f1b6";
}
.icon-addon-drive:after {
    content: "\f1b6";
}
.icon-dump-index-files:before {
    content: "\f1b7";
}
.icon-addon-dump-index-files:after {
    content: "\f1b7";
}
.icon-edited:before {
    content: "\f1b8";
}
.icon-addon-edited:after {
    content: "\f1b8";
}
.icon-elasticsearch:before {
    content: "\f1b9";
}
.icon-addon-elasticsearch:after {
    content: "\f1b9";
}
.icon-embed:before {
    content: "\f1ba";
}
.icon-addon-embed:after {
    content: "\f1ba";
}
.icon-encryption:before {
    content: "\f1bb";
}
.icon-addon-encryption:after {
    content: "\f1bb";
}
.icon-etag:before {
    content: "\f1bc";
}
.icon-addon-etag:after {
    content: "\f1bc";
}
.icon-etl:before {
    content: "\f1bd";
}
.icon-addon-etl:after {
    content: "\f1bd";
}
.icon-exclamation:before {
    content: "\f1be";
}
.icon-addon-exclamation:after {
    content: "\f1be";
}
.icon-exit-fullscreen:before {
    content: "\f1bf";
}
.icon-addon-exit-fullscreen:after {
    content: "\f1bf";
}
.icon-expand-horizontal:before {
    content: "\f1c0";
}
.icon-addon-expand-horizontal:after {
    content: "\f1c0";
}
.icon-expand-vertical:before {
    content: "\f1c1";
}
.icon-addon-expand-vertical:after {
    content: "\f1c1";
}
.icon-expand:before {
    content: "\f1c2";
}
.icon-addon-expand:after {
    content: "\f1c2";
}
.icon-experimental:before {
    content: "\f1c3";
}
.icon-addon-experimental:after {
    content: "\f1c3";
}
.icon-expiration:before {
    content: "\f1c4";
}
.icon-addon-expiration:after {
    content: "\f1c4";
}
.icon-export-database:before {
    content: "\f1c5";
}
.icon-addon-export-database:after {
    content: "\f1c5";
}
.icon-export:before {
    content: "\f1c6";
}
.icon-addon-export:after {
    content: "\f1c6";
}
.icon-expos-refresh:before {
    content: "\f1c7";
}
.icon-addon-expos-refresh:after {
    content: "\f1c7";
}
.icon-facebook:before {
    content: "\f1c8";
}
.icon-addon-facebook:after {
    content: "\f1c8";
}
.icon-feedback:before {
    content: "\f1c9";
}
.icon-addon-feedback:after {
    content: "\f1c9";
}
.icon-file-import:before {
    content: "\f1ca";
}
.icon-addon-file-import:after {
    content: "\f1ca";
}
.icon-filesystem:before {
    content: "\f1cb";
}
.icon-addon-filesystem:after {
    content: "\f1cb";
}
.icon-filter:before {
    content: "\f1cc";
}
.icon-addon-filter:after {
    content: "\f1cc";
}
.icon-firefox:before {
    content: "\f1cd";
}
.icon-addon-firefox:after {
    content: "\f1cd";
}
.icon-flag:before {
    content: "\f1ce";
}
.icon-addon-flag:after {
    content: "\f1ce";
}
.icon-fold:before {
    content: "\f1cf";
}
.icon-addon-fold:after {
    content: "\f1cf";
}
.icon-folder:before {
    content: "\f1d0";
}
.icon-addon-folder:after {
    content: "\f1d0";
}
.icon-force:before {
    content: "\f1d1";
}
.icon-addon-force:after {
    content: "\f1d1";
}
.icon-fullscreen:before {
    content: "\f1d2";
}
.icon-addon-fullscreen:after {
    content: "\f1d2";
}
.icon-gather-debug-information:before {
    content: "\f1d3";
}
.icon-addon-gather-debug-information:after {
    content: "\f1d3";
}
.icon-gcp:before {
    content: "\f1d4";
}
.icon-addon-gcp:after {
    content: "\f1d4";
}
.icon-generation:before {
    content: "\f1d5";
}
.icon-addon-generation:after {
    content: "\f1d5";
}
.icon-github:before {
    content: "\f1d6";
}
.icon-addon-github:after {
    content: "\f1d6";
}
.icon-global-config:before {
    content: "\f1d7";
}
.icon-addon-global-config:after {
    content: "\f1d7";
}
.icon-global-cutout:before {
    content: "\f1d8";
}
.icon-addon-global-cutout:after {
    content: "\f1d8";
}
.icon-global:before {
    content: "\f1d9";
}
.icon-addon-global:after {
    content: "\f1d9";
}
.icon-googleplus:before {
    content: "\f1da";
}
.icon-addon-googleplus:after {
    content: "\f1da";
}
.icon-graph-range:before {
    content: "\f1db";
}
.icon-addon-graph-range:after {
    content: "\f1db";
}
.icon-graph:before {
    content: "\f1dc";
}
.icon-addon-graph:after {
    content: "\f1dc";
}
.icon-group:before {
    content: "\f1dd";
}
.icon-addon-group:after {
    content: "\f1dd";
}
.icon-hammer-driver:before {
    content: "\f1de";
}
.icon-addon-hammer-driver:after {
    content: "\f1de";
}
.icon-hammer:before {
    content: "\f1df";
}
.icon-addon-hammer:after {
    content: "\f1df";
}
.icon-hash:before {
    content: "\f1e0";
}
.icon-addon-hash:after {
    content: "\f1e0";
}
.icon-help:before {
    content: "\f1e1";
}
.icon-addon-help:after {
    content: "\f1e1";
}
.icon-home:before {
    content: "\f1e2";
}
.icon-addon-home:after {
    content: "\f1e2";
}
.icon-hot-spare:before {
    content: "\f1e3";
}
.icon-addon-hot-spare:after {
    content: "\f1e3";
}
.icon-icons_backup:before {
    content: "\f1e4";
}
.icon-addon-icons_backup:after {
    content: "\f1e4";
}
.icon-icons_database:before {
    content: "\f1e5";
}
.icon-addon-icons_database:after {
    content: "\f1e5";
}
.icon-identities:before {
    content: "\f1e6";
}
.icon-addon-identities:after {
    content: "\f1e6";
}
.icon-import-database:before {
    content: "\f1e7";
}
.icon-addon-import-database:after {
    content: "\f1e7";
}
.icon-import:before {
    content: "\f1e8";
}
.icon-addon-import:after {
    content: "\f1e8";
}
.icon-indent:before {
    content: "\f1e9";
}
.icon-addon-indent:after {
    content: "\f1e9";
}
.icon-index-batch-size:before {
    content: "\f1ea";
}
.icon-addon-index-batch-size:after {
    content: "\f1ea";
}
.icon-index-cleanup:before {
    content: "\f1eb";
}
.icon-addon-index-cleanup:after {
    content: "\f1eb";
}
.icon-index-errors:before {
    content: "\f1ec";
}
.icon-addon-index-errors:after {
    content: "\f1ec";
}
.icon-index-export:before {
    content: "\f1ed";
}
.icon-addon-index-export:after {
    content: "\f1ed";
}
.icon-index-fields:before {
    content: "\f1ee";
}
.icon-addon-index-fields:after {
    content: "\f1ee";
}
.icon-index-history:before {
    content: "\f1ef";
}
.icon-addon-index-history:after {
    content: "\f1ef";
}
.icon-index-import:before {
    content: "\f1f0";
}
.icon-addon-index-import:after {
    content: "\f1f0";
}
.icon-index-stats:before {
    content: "\f1f1";
}
.icon-addon-index-stats:after {
    content: "\f1f1";
}
.icon-index:before {
    content: "\f1f2";
}
.icon-addon-index:after {
    content: "\f1f2";
}
.icon-indexes-query:before {
    content: "\f1f3";
}
.icon-addon-indexes-query:after {
    content: "\f1f3";
}
.icon-indexing-performance:before {
    content: "\f1f4";
}
.icon-addon-indexing-performance:after {
    content: "\f1f4";
}
.icon-indexing-progess:before {
    content: "\f1f5";
}
.icon-addon-indexing-progess:after {
    content: "\f1f5";
}
.icon-indexing:before {
    content: "\f1f6";
}
.icon-addon-indexing:after {
    content: "\f1f6";
}
.icon-infinity:before {
    content: "\f1f7";
}
.icon-addon-infinity:after {
    content: "\f1f7";
}
.icon-integrations:before {
    content: "\f1f8";
}
.icon-addon-integrations:after {
    content: "\f1f8";
}
.icon-io-test:before {
    content: "\f1f9";
}
.icon-addon-io-test:after {
    content: "\f1f9";
}
.icon-javascript:before {
    content: "\f1fa";
}
.icon-addon-javascript:after {
    content: "\f1fa";
}
.icon-json:before {
    content: "\f1fb";
}
.icon-addon-json:after {
    content: "\f1fb";
}
.icon-kafka:before {
    content: "\f1fc";
}
.icon-addon-kafka:after {
    content: "\f1fc";
}
.icon-key:before {
    content: "\f1fd";
}
.icon-addon-key:after {
    content: "\f1fd";
}
.icon-kill-query:before {
    content: "\f1fe";
}
.icon-addon-kill-query:after {
    content: "\f1fe";
}
.icon-latest:before {
    content: "\f1ff";
}
.icon-addon-latest:after {
    content: "\f1ff";
}
.icon-lets-encrypt:before {
    content: "\f200";
}
.icon-addon-lets-encrypt:after {
    content: "\f200";
}
.icon-license-information:before {
    content: "\f201";
}
.icon-addon-license-information:after {
    content: "\f201";
}
.icon-license:before {
    content: "\f202";
}
.icon-addon-license:after {
    content: "\f202";
}
.icon-link:before {
    content: "\f203";
}
.icon-addon-link:after {
    content: "\f203";
}
.icon-linkedin:before {
    content: "\f204";
}
.icon-addon-linkedin:after {
    content: "\f204";
}
.icon-linux:before {
    content: "\f205";
}
.icon-addon-linux:after {
    content: "\f205";
}
.icon-list-of-indexes:before {
    content: "\f206";
}
.icon-addon-list-of-indexes:after {
    content: "\f206";
}
.icon-list:before {
    content: "\f207";
}
.icon-addon-list:after {
    content: "\f207";
}
.icon-load-index:before {
    content: "\f208";
}
.icon-addon-load-index:after {
    content: "\f208";
}
.icon-load-map-reduce:before {
    content: "\f209";
}
.icon-addon-load-map-reduce:after {
    content: "\f209";
}
.icon-lock-cutout:before {
    content: "\f20a";
}
.icon-addon-lock-cutout:after {
    content: "\f20a";
}
.icon-lock-error:before {
    content: "\f20b";
}
.icon-addon-lock-error:after {
    content: "\f20b";
}
.icon-lock-sidebyside:before {
    content: "\f20c";
}
.icon-addon-lock-sidebyside:after {
    content: "\f20c";
}
.icon-logo:before {
    content: "\f20d";
}
.icon-addon-logo:after {
    content: "\f20d";
}
.icon-logout:before {
    content: "\f20e";
}
.icon-addon-logout:after {
    content: "\f20e";
}
.icon-logs:before {
    content: "\f20f";
}
.icon-addon-logs:after {
    content: "\f20f";
}
.icon-magic-wand:before {
    content: "\f210";
}
.icon-addon-magic-wand:after {
    content: "\f210";
}
.icon-manage-connection-strings:before {
    content: "\f211";
}
.icon-addon-manage-connection-strings:after {
    content: "\f211";
}
.icon-manage-dbgroup:before {
    content: "\f212";
}
.icon-addon-manage-dbgroup:after {
    content: "\f212";
}
.icon-manage-ongoing-tasks:before {
    content: "\f213";
}
.icon-addon-manage-ongoing-tasks:after {
    content: "\f213";
}
.icon-manage-server-io-test:before {
    content: "\f214";
}
.icon-addon-manage-server-io-test:after {
    content: "\f214";
}
.icon-manage-server-running-queries:before {
    content: "\f215";
}
.icon-addon-manage-server-running-queries:after {
    content: "\f215";
}
.icon-manage-server:before {
    content: "\f216";
}
.icon-addon-manage-server:after {
    content: "\f216";
}
.icon-map-reduce-visualizer:before {
    content: "\f217";
}
.icon-addon-map-reduce-visualizer:after {
    content: "\f217";
}
.icon-map-reduce:before {
    content: "\f218";
}
.icon-addon-map-reduce:after {
    content: "\f218";
}
.icon-map:before {
    content: "\f219";
}
.icon-addon-map:after {
    content: "\f219";
}
.icon-memory:before {
    content: "\f21a";
}
.icon-addon-memory:after {
    content: "\f21a";
}
.icon-menu-collapse:before {
    content: "\f21b";
}
.icon-addon-menu-collapse:after {
    content: "\f21b";
}
.icon-menu-expand:before {
    content: "\f21c";
}
.icon-addon-menu-expand:after {
    content: "\f21c";
}
.icon-menu-icons_checkbox-on:before {
    content: "\f21d";
}
.icon-addon-menu-icons_checkbox-on:after {
    content: "\f21d";
}
.icon-menu-icons_replication-58:before {
    content: "\f21e";
}
.icon-addon-menu-icons_replication-58:after {
    content: "\f21e";
}
.icon-menu-icons_settings:before {
    content: "\f21f";
}
.icon-addon-menu-icons_settings:after {
    content: "\f21f";
}
.icon-menu-icons_srorage:before {
    content: "\f220";
}
.icon-addon-menu-icons_srorage:after {
    content: "\f220";
}
.icon-menu-icons_stats-36:before {
    content: "\f221";
}
.icon-addon-menu-icons_stats-36:after {
    content: "\f221";
}
.icon-menu:before {
    content: "\f222";
}
.icon-addon-menu:after {
    content: "\f222";
}
.icon-merge:before {
    content: "\f223";
}
.icon-addon-merge:after {
    content: "\f223";
}
.icon-metrics:before {
    content: "\f224";
}
.icon-addon-metrics:after {
    content: "\f224";
}
.icon-minus:before {
    content: "\f225";
}
.icon-addon-minus:after {
    content: "\f225";
}
.icon-nested-document-property:before {
    content: "\f226";
}
.icon-addon-nested-document-property:after {
    content: "\f226";
}
.icon-new-counter:before {
    content: "\f227";
}
.icon-addon-new-counter:after {
    content: "\f227";
}
.icon-new-database:before {
    content: "\f228";
}
.icon-addon-new-database:after {
    content: "\f228";
}
.icon-new-document:before {
    content: "\f229";
}
.icon-addon-new-document:after {
    content: "\f229";
}
.icon-new-filesystem:before {
    content: "\f22a";
}
.icon-addon-new-filesystem:after {
    content: "\f22a";
}
.icon-new-time-series - Copy:before {
    content: "\f22b";
}
.icon-addon-new-time-series - Copy:after {
    content: "\f22b";
}
.icon-new-time-series:before {
    content: "\f22c";
}
.icon-addon-new-time-series:after {
    content: "\f22c";
}
.icon-newline:before {
    content: "\f22d";
}
.icon-addon-newline:after {
    content: "\f22d";
}
.icon-newtab:before {
    content: "\f22e";
}
.icon-addon-newtab:after {
    content: "\f22e";
}
.icon-node-add:before {
    content: "\f22f";
}
.icon-addon-node-add:after {
    content: "\f22f";
}
.icon-node:before {
    content: "\f230";
}
.icon-addon-node:after {
    content: "\f230";
}
.icon-notifications:before {
    content: "\f231";
}
.icon-addon-notifications:after {
    content: "\f231";
}
.icon-olap:before {
    content: "\f232";
}
.icon-addon-olap:after {
    content: "\f232";
}
.icon-ongoing-tasks:before {
    content: "\f233";
}
.icon-addon-ongoing-tasks:after {
    content: "\f233";
}
.icon-orchestrator:before {
    content: "\f234";
}
.icon-addon-orchestrator:after {
    content: "\f234";
}
.icon-order:before {
    content: "\f235";
}
.icon-addon-order:after {
    content: "\f235";
}
.icon-os:before {
    content: "\f236";
}
.icon-addon-os:after {
    content: "\f236";
}
.icon-other:before {
    content: "\f237";
}
.icon-addon-other:after {
    content: "\f237";
}
.icon-output-collection:before {
    content: "\f238";
}
.icon-addon-output-collection:after {
    content: "\f238";
}
.icon-parallel-stacks:before {
    content: "\f239";
}
.icon-addon-parallel-stacks:after {
    content: "\f239";
}
.icon-paste:before {
    content: "\f23a";
}
.icon-addon-paste:after {
    content: "\f23a";
}
.icon-patch:before {
    content: "\f23b";
}
.icon-addon-patch:after {
    content: "\f23b";
}
.icon-path:before {
    content: "\f23c";
}
.icon-addon-path:after {
    content: "\f23c";
}
.icon-pause:before {
    content: "\f23d";
}
.icon-addon-pause:after {
    content: "\f23d";
}
.icon-percent:before {
    content: "\f23e";
}
.icon-addon-percent:after {
    content: "\f23e";
}
.icon-periodic-backup:before {
    content: "\f23f";
}
.icon-addon-periodic-backup:after {
    content: "\f23f";
}
.icon-periodic-export-13:before {
    content: "\f240";
}
.icon-addon-periodic-export-13:after {
    content: "\f240";
}
.icon-periodic-export:before {
    content: "\f241";
}
.icon-addon-periodic-export:after {
    content: "\f241";
}
.icon-phone:before {
    content: "\f242";
}
.icon-addon-phone:after {
    content: "\f242";
}
.icon-pin:before {
    content: "\f243";
}
.icon-addon-pin:after {
    content: "\f243";
}
.icon-pinned:before {
    content: "\f244";
}
.icon-addon-pinned:after {
    content: "\f244";
}
.icon-placeholder (1):before {
    content: "\f245";
}
.icon-addon-placeholder (1):after {
    content: "\f245";
}
.icon-play:before {
    content: "\f246";
}
.icon-addon-play:after {
    content: "\f246";
}
.icon-play2:before {
    content: "\f247";
}
.icon-addon-play2:after {
    content: "\f247";
}
.icon-plus:before {
    content: "\f248";
}
.icon-addon-plus:after {
    content: "\f248";
}
.icon-postgresql:before {
    content: "\f249";
}
.icon-addon-postgresql:after {
    content: "\f249";
}
.icon-postpone:before {
    content: "\f24a";
}
.icon-addon-postpone:after {
    content: "\f24a";
}
.icon-powerbi:before {
    content: "\f24b";
}
.icon-addon-powerbi:after {
    content: "\f24b";
}
.icon-prefetches:before {
    content: "\f24c";
}
.icon-addon-prefetches:after {
    content: "\f24c";
}
.icon-preview-off:before {
    content: "\f24d";
}
.icon-addon-preview-off:after {
    content: "\f24d";
}
.icon-print:before {
    content: "\f24e";
}
.icon-addon-print:after {
    content: "\f24e";
}
.icon-processor:before {
    content: "\f24f";
}
.icon-addon-processor:after {
    content: "\f24f";
}
.icon-promote:before {
    content: "\f250";
}
.icon-addon-promote:after {
    content: "\f250";
}
.icon-queries:before {
    content: "\f251";
}
.icon-addon-queries:after {
    content: "\f251";
}
.icon-query:before {
    content: "\f252";
}
.icon-addon-query:after {
    content: "\f252";
}
.icon-quotas:before {
    content: "\f253";
}
.icon-addon-quotas:after {
    content: "\f253";
}
.icon-rabbitmq:before {
    content: "\f254";
}
.icon-addon-rabbitmq:after {
    content: "\f254";
}
.icon-random:before {
    content: "\f255";
}
.icon-addon-random:after {
    content: "\f255";
}
.icon-raven:before {
    content: "\f256";
}
.icon-addon-raven:after {
    content: "\f256";
}
.icon-ravendb-data:before {
    content: "\f257";
}
.icon-addon-ravendb-data:after {
    content: "\f257";
}
.icon-reassign-cores:before {
    content: "\f258";
}
.icon-addon-reassign-cores:after {
    content: "\f258";
}
.icon-recent:before {
    content: "\f259";
}
.icon-addon-recent:after {
    content: "\f259";
}
.icon-reference-pattern:before {
    content: "\f25a";
}
.icon-addon-reference-pattern:after {
    content: "\f25a";
}
.icon-referenced-collections:before {
    content: "\f25b";
}
.icon-addon-referenced-collections:after {
    content: "\f25b";
}
.icon-refresh-stats:before {
    content: "\f25c";
}
.icon-addon-refresh-stats:after {
    content: "\f25c";
}
.icon-refresh:before {
    content: "\f25d";
}
.icon-addon-refresh:after {
    content: "\f25d";
}
.icon-rejected:before {
    content: "\f25e";
}
.icon-addon-rejected:after {
    content: "\f25e";
}
.icon-reorder:before {
    content: "\f25f";
}
.icon-addon-reorder:after {
    content: "\f25f";
}
.icon-replace:before {
    content: "\f260";
}
.icon-addon-replace:after {
    content: "\f260";
}
.icon-replication-stats:before {
    content: "\f261";
}
.icon-addon-replication-stats:after {
    content: "\f261";
}
.icon-replication:before {
    content: "\f262";
}
.icon-addon-replication:after {
    content: "\f262";
}
.icon-reset-index:before {
    content: "\f263";
}
.icon-addon-reset-index:after {
    content: "\f263";
}
.icon-reset:before {
    content: "\f264";
}
.icon-addon-reset:after {
    content: "\f264";
}
.icon-resources:before {
    content: "\f265";
}
.icon-addon-resources:after {
    content: "\f265";
}
.icon-restore-backup:before {
    content: "\f266";
}
.icon-addon-restore-backup:after {
    content: "\f266";
}
.icon-restore:before {
    content: "\f267";
}
.icon-addon-restore:after {
    content: "\f267";
}
.icon-revert-revisions:before {
    content: "\f268";
}
.icon-addon-revert-revisions:after {
    content: "\f268";
}
.icon-revert:before {
    content: "\f269";
}
.icon-addon-revert:after {
    content: "\f269";
}
.icon-revisions-bin:before {
    content: "\f26a";
}
.icon-addon-revisions-bin:after {
    content: "\f26a";
}
.icon-revisions:before {
    content: "\f26b";
}
.icon-addon-revisions:after {
    content: "\f26b";
}
.icon-road-cone:before {
    content: "\f26c";
}
.icon-addon-road-cone:after {
    content: "\f26c";
}
.icon-rocket:before {
    content: "\f26d";
}
.icon-addon-rocket:after {
    content: "\f26d";
}
.icon-running-queries:before {
    content: "\f26e";
}
.icon-addon-running-queries:after {
    content: "\f26e";
}
.icon-running-tasks:before {
    content: "\f26f";
}
.icon-addon-running-tasks:after {
    content: "\f26f";
}
.icon-save:before {
    content: "\f270";
}
.icon-addon-save:after {
    content: "\f270";
}
.icon-search:before {
    content: "\f271";
}
.icon-addon-search:after {
    content: "\f271";
}
.icon-server-settings:before {
    content: "\f272";
}
.icon-addon-server-settings:after {
    content: "\f272";
}
.icon-server-smuggling:before {
    content: "\f273";
}
.icon-addon-server-smuggling:after {
    content: "\f273";
}
.icon-server-topology:before {
    content: "\f274";
}
.icon-addon-server-topology:after {
    content: "\f274";
}
.icon-server-wide-custom-analyzers:before {
    content: "\f275";
}
.icon-addon-server-wide-custom-analyzers:after {
    content: "\f275";
}
.icon-server-wide-custom-sorters:before {
    content: "\f276";
}
.icon-addon-server-wide-custom-sorters:after {
    content: "\f276";
}
.icon-server-wide-replication:before {
    content: "\f277";
}
.icon-addon-server-wide-replication:after {
    content: "\f277";
}
.icon-server-wide-tasks:before {
    content: "\f278";
}
.icon-addon-server-wide-tasks:after {
    content: "\f278";
}
.icon-settings:before {
    content: "\f279";
}
.icon-addon-settings:after {
    content: "\f279";
}
.icon-shard:before {
    content: "\f27a";
}
.icon-addon-shard:after {
    content: "\f27a";
}
.icon-sharding:before {
    content: "\f27b";
}
.icon-addon-sharding:after {
    content: "\f27b";
}
.icon-shield:before {
    content: "\f27c";
}
.icon-addon-shield:after {
    content: "\f27c";
}
.icon-shuffle:before {
    content: "\f27d";
}
.icon-addon-shuffle:after {
    content: "\f27d";
}
.icon-skip:before {
    content: "\f27e";
}
.icon-addon-skip:after {
    content: "\f27e";
}
.icon-snapshot-backup:before {
    content: "\f27f";
}
.icon-addon-snapshot-backup:after {
    content: "\f27f";
}
.icon-sortby:before {
    content: "\f280";
}
.icon-addon-sortby:after {
    content: "\f280";
}
.icon-sparkles:before {
    content: "\f281";
}
.icon-addon-sparkles:after {
    content: "\f281";
}
.icon-spatial-map-view:before {
    content: "\f282";
}
.icon-addon-spatial-map-view:after {
    content: "\f282";
}
.icon-sql-attachment:before {
    content: "\f283";
}
.icon-addon-sql-attachment:after {
    content: "\f283";
}
.icon-sql-binary:before {
    content: "\f284";
}
.icon-addon-sql-binary:after {
    content: "\f284";
}
.icon-sql-boolean:before {
    content: "\f285";
}
.icon-addon-sql-boolean:after {
    content: "\f285";
}
.icon-sql-boolean2:before {
    content: "\f286";
}
.icon-addon-sql-boolean2:after {
    content: "\f286";
}
.icon-sql-document-id:before {
    content: "\f287";
}
.icon-addon-sql-document-id:after {
    content: "\f287";
}
.icon-sql-many-to-one:before {
    content: "\f288";
}
.icon-addon-sql-many-to-one:after {
    content: "\f288";
}
.icon-sql-number:before {
    content: "\f289";
}
.icon-addon-sql-number:after {
    content: "\f289";
}
.icon-sql-one-to-many:before {
    content: "\f28a";
}
.icon-addon-sql-one-to-many:after {
    content: "\f28a";
}
.icon-sql-replication-stats:before {
    content: "\f28b";
}
.icon-addon-sql-replication-stats:after {
    content: "\f28b";
}
.icon-sql-replication:before {
    content: "\f28c";
}
.icon-addon-sql-replication:after {
    content: "\f28c";
}
.icon-sql-string:before {
    content: "\f28d";
}
.icon-addon-sql-string:after {
    content: "\f28d";
}
.icon-sql-unsupported:before {
    content: "\f28e";
}
.icon-addon-sql-unsupported:after {
    content: "\f28e";
}
.icon-stack-traces:before {
    content: "\f28f";
}
.icon-addon-stack-traces:after {
    content: "\f28f";
}
.icon-star-filled:before {
    content: "\f290";
}
.icon-addon-star-filled:after {
    content: "\f290";
}
.icon-star:before {
    content: "\f291";
}
.icon-addon-star:after {
    content: "\f291";
}
.icon-start:before {
    content: "\f292";
}
.icon-addon-start:after {
    content: "\f292";
}
.icon-stats-menu:before {
    content: "\f293";
}
.icon-addon-stats-menu:after {
    content: "\f293";
}
.icon-stats-running-queries:before {
    content: "\f294";
}
.icon-addon-stats-running-queries:after {
    content: "\f294";
}
.icon-stats:before {
    content: "\f295";
}
.icon-addon-stats:after {
    content: "\f295";
}
.icon-stepdown:before {
    content: "\f296";
}
.icon-addon-stepdown:after {
    content: "\f296";
}
.icon-stop:before {
    content: "\f297";
}
.icon-addon-stop:after {
    content: "\f297";
}
.icon-storage-free:before {
    content: "\f298";
}
.icon-addon-storage-free:after {
    content: "\f298";
}
.icon-storage-used:before {
    content: "\f299";
}
.icon-addon-storage-used:after {
    content: "\f299";
}
.icon-storage:before {
    content: "\f29a";
}
.icon-addon-storage:after {
    content: "\f29a";
}
.icon-studio-config:before {
    content: "\f29b";
}
.icon-addon-studio-config:after {
    content: "\f29b";
}
.icon-studio-configuration:before {
    content: "\f29c";
}
.icon-addon-studio-configuration:after {
    content: "\f29c";
}
.icon-subscriptions:before {
    content: "\f29d";
}
.icon-addon-subscriptions:after {
    content: "\f29d";
}
.icon-sum:before {
    content: "\f29e";
}
.icon-addon-sum:after {
    content: "\f29e";
}
.icon-support:before {
    content: "\f29f";
}
.icon-addon-support:after {
    content: "\f29f";
}
.icon-swap:before {
    content: "\f2a0";
}
.icon-addon-swap:after {
    content: "\f2a0";
}
.icon-system-storage:before {
    content: "\f2a1";
}
.icon-addon-system-storage:after {
    content: "\f2a1";
}
.icon-system:before {
    content: "\f2a2";
}
.icon-addon-system:after {
    content: "\f2a2";
}
.icon-table:before {
    content: "\f2a3";
}
.icon-addon-table:after {
    content: "\f2a3";
}
.icon-tarfic-watch:before {
    content: "\f2a4";
}
.icon-addon-tarfic-watch:after {
    content: "\f2a4";
}
.icon-tasks-list:before {
    content: "\f2a5";
}
.icon-addon-tasks-list:after {
    content: "\f2a5";
}
.icon-tasks-menu:before {
    content: "\f2a6";
}
.icon-addon-tasks-menu:after {
    content: "\f2a6";
}
.icon-tasks:before {
    content: "\f2a7";
}
.icon-addon-tasks:after {
    content: "\f2a7";
}
.icon-terms:before {
    content: "\f2a8";
}
.icon-addon-terms:after {
    content: "\f2a8";
}
.icon-test:before {
    content: "\f2a9";
}
.icon-addon-test:after {
    content: "\f2a9";
}
.icon-theme:before {
    content: "\f2aa";
}
.icon-addon-theme:after {
    content: "\f2aa";
}
.icon-thread-stack-trace:before {
    content: "\f2ab";
}
.icon-addon-thread-stack-trace:after {
    content: "\f2ab";
}
.icon-thumb-down:before {
    content: "\f2ac";
}
.icon-addon-thumb-down:after {
    content: "\f2ac";
}
.icon-thumb-up:before {
    content: "\f2ad";
}
.icon-addon-thumb-up:after {
    content: "\f2ad";
}
.icon-tick:before {
    content: "\f2ae";
}
.icon-addon-tick:after {
    content: "\f2ae";
}
.icon-timeseries-settings:before {
    content: "\f2af";
}
.icon-addon-timeseries-settings:after {
    content: "\f2af";
}
.icon-timeseries:before {
    content: "\f2b0";
}
.icon-addon-timeseries:after {
    content: "\f2b0";
}
.icon-toggle-off:before {
    content: "\f2b1";
}
.icon-addon-toggle-off:after {
    content: "\f2b1";
}
.icon-toggle-on:before {
    content: "\f2b2";
}
.icon-addon-toggle-on:after {
    content: "\f2b2";
}
.icon-topology:before {
    content: "\f2b3";
}
.icon-addon-topology:after {
    content: "\f2b3";
}
.icon-traffic-watch:before {
    content: "\f2b4";
}
.icon-addon-traffic-watch:after {
    content: "\f2b4";
}
.icon-traffic:before {
    content: "\f2b5";
}
.icon-addon-traffic:after {
    content: "\f2b5";
}
.icon-transaction-record-replay:before {
    content: "\f2b6";
}
.icon-addon-transaction-record-replay:after {
    content: "\f2b6";
}
.icon-transaction-record:before {
    content: "\f2b7";
}
.icon-addon-transaction-record:after {
    content: "\f2b7";
}
.icon-transform-results:before {
    content: "\f2b8";
}
.icon-addon-transform-results:after {
    content: "\f2b8";
}
.icon-transformer:before {
    content: "\f2b9";
}
.icon-addon-transformer:after {
    content: "\f2b9";
}
.icon-trash-cutout:before {
    content: "\f2ba";
}
.icon-addon-trash-cutout:after {
    content: "\f2ba";
}
.icon-twitter:before {
    content: "\f2bb";
}
.icon-addon-twitter:after {
    content: "\f2bb";
}
.icon-umbrella:before {
    content: "\f2bc";
}
.icon-addon-umbrella:after {
    content: "\f2bc";
}
.icon-unencrypted:before {
    content: "\f2bd";
}
.icon-addon-unencrypted:after {
    content: "\f2bd";
}
.icon-unlock:before {
    content: "\f2be";
}
.icon-addon-unlock:after {
    content: "\f2be";
}
.icon-unsecure:before {
    content: "\f2bf";
}
.icon-addon-unsecure:after {
    content: "\f2bf";
}
.icon-unsupported-browser:before {
    content: "\f2c0";
}
.icon-addon-unsupported-browser:after {
    content: "\f2c0";
}
.icon-upgrade-arrow:before {
    content: "\f2c1";
}
.icon-addon-upgrade-arrow:after {
    content: "\f2c1";
}
.icon-upload:before {
    content: "\f2c2";
}
.icon-addon-upload:after {
    content: "\f2c2";
}
.icon-user-info:before {
    content: "\f2c3";
}
.icon-addon-user-info:after {
    content: "\f2c3";
}
.icon-user:before {
    content: "\f2c4";
}
.icon-addon-user:after {
    content: "\f2c4";
}
.icon-vector:before {
    content: "\f2c5";
}
.icon-addon-vector:after {
    content: "\f2c5";
}
.icon-versioning:before {
    content: "\f2c6";
}
.icon-addon-versioning:after {
    content: "\f2c6";
}
.icon-web-socket:before {
    content: "\f2c7";
}
.icon-addon-web-socket:after {
    content: "\f2c7";
}
.icon-windows:before {
    content: "\f2c8";
}
.icon-addon-windows:after {
    content: "\f2c8";
}
.icon-x:before {
    content: "\f2c9";
}
.icon-addon-x:after {
    content: "\f2c9";
}
.icon-zombie:before {
    content: "\f2ca";
}
.icon-addon-zombie:after {
    content: "\f2ca";
}
