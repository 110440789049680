@use "sass:math";

@use "Content/scss/colors";
@use "Content/scss/sizes";

.create-database {
    .step-img {
        height: 140px;
        margin: auto;
    }

    .replication-input {
        width: 5em !important;
    }
}
