@use "Content/scss/bs5variables";

.input-group {
    .input-group-text ~ div > .react-select-container {
        border-radius: 0 bs5variables.$input-border-radius bs5variables.$input-border-radius 0;
    }
}

.react-select-container {
    border: 1px solid bs5variables.$border-color-light-var;
    border-radius: bs5variables.$input-border-radius;
    flex: 1 1 auto;

    .react-select__control {
        border: none;
        background-color: bs5variables.$well-bg-var;
        box-shadow: none;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 0 0.25rem rgba(bs5variables.$primary-var, 0.25);
        }

        .react-select__value-container {
            * {
                color: bs5variables.$text-color-var;
            }
            .react-select__multi-value {
                background-color: bs5variables.$panel-bg-2-var;

                .react-select__multi-value__remove {
                    background-color: bs5variables.$panel-bg-2-var;

                    &:hover {
                        background-color: bs5variables.$panel-bg-3-var;
                    }
                }
            }
        }

        .react-select__indicator-separator {
            display: none;
        }
    }

    .react-select__control--is-disabled {
        background-color: bs5variables.$panel-bg-1-var;
        pointer-events: all !important;
        cursor: not-allowed !important;
        opacity: 0.66 !important;

        .react-select__dropdown-indicator {
            color: bs5variables.$border-color-light-var;
        }
    }

    .react-select__menu {
        border: 1px solid bs5variables.$border-color-light-var;
        border-radius: bs5variables.$border-radius;
        background-color: bs5variables.$panel-bg-2-var;
        z-index: bs5variables.$zindex-dropdown;

        .react-select__menu-list {
            .react-select__option {
                padding: bs5variables.$gutter-xxs bs5variables.$gutter-xs;
                background-color: transparent;
                color: bs5variables.$text-color-var;
                cursor: pointer;

                &:hover {
                    background-color: bs5variables.$panel-bg-3-var;
                    color: bs5variables.$text-emphasis-var;
                }
            }

            .react-select__option--is-focused {
                background-color: bs5variables.$panel-bg-3-var;
                color: bs5variables.$text-emphasis-var;
            }

            .react-select__option--is-disabled {
                opacity: 0.5;
                color: bs5variables.$text-muted-var;
                cursor: not-allowed;
            }

            hr {
                margin: 0 bs5variables.$gutter-xxs;
            }
        }
    }
}
