@use "Content/scss/colors";
@use "Content/scss/sizes";

.location-specific-details-tooltip {
    color: colors.$text-color-var;
    background-color: colors.$panel-bg-1-var;
    border-radius: sizes.$border-radius-md;
    display: grid;
    grid-template-columns: auto 1fr;
    z-index: 1000;
    min-width: 350px;

    .location-specific-details-location {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: sizes.$gutter-xs sizes.$gutter-xs;
        border-right: 1px solid colors.$border-color-light-var;
        background-color: colors.$panel-header-bg-var;
        border-top-left-radius: sizes.$gutter-xs;
        border-bottom-left-radius: sizes.$gutter-xs;
        min-width: 135px;

        & > div:first-of-type {
            font-size: 36px;
            margin-bottom: sizes.$gutter-sm;
        }

        .shard-id,
        .node-id {
            font-size: 26px;
            font-weight: bold;
            color: colors.$text-emphasis-var;
            text-align: center;
            line-height: 1.3em;

            .small-label {
                line-height: 1.4em;
            }

            img {
                width: 1.4em;
            }
        }
    }

    .details {
        line-height: 1em;
        border-top-right-radius: sizes.$gutter-xs;
        border-bottom-right-radius: sizes.$gutter-xs;
        background-color: colors.$panel-header-bg-var;
        display: flex;
        flex-direction: column;

        .details-item {
            padding: sizes.$gutter-xs sizes.$gutter-sm;
            font-size: 12px;
            flex-grow: 1;
            display: flex;
            align-items: center;

            & + .details-item {
                border-top: 1px solid colors.$border-color-light-var;
            }

            i {
                margin-right: sizes.$gutter-xxs;
            }

            &.status.updating {
                color: colors.$progress-var;
            }
        }
    }
}
