@use "Content/scss/colors";
@use "Content/scss/sizes";

.named-progress-container {
    display: contents;
}

.named-progress {
    padding: sizes.$gutter-xs sizes.$gutter-sm;
}

.named-progress {
    display: flex;

    .progress-item {
        font-size: 9px;
        text-transform: uppercase;
        letter-spacing: 0.15em;

        .progress-label {
            white-space: nowrap;
        }

        .progress-percentage {
            font-size: 11px;
        }

        .progress {
            margin-top: 0;
            height: 3px;
            margin-bottom: 0;

            .progress-bar {
                background-color: colors.$progress-color;
                &.completed {
                    background-color: colors.$success;
                }
            }
        }

        & + .progress-item {
            margin-left: sizes.$gutter-xs;
        }
    }
}
