@use "Content/scss/sizes";
@use "Content/scss/colors";

$step-bullet-size: 30px;
$step-icon-size: 22px;
$active-color: colors.$primary;
.steps {
    display: flex;
    align-items: center;
    padding: 0 sizes.$gutter-sm sizes.$gutter-sm;

    .steps-item {
        position: relative;
        cursor: pointer;

        .steps-label {
            position: absolute;
            left: 50%;
            padding: sizes.$gutter-xs;
            transform: translateX(-50%);
            text-align: center;
            transition: color 0.4s sizes.$easing-rubber;
        }

        .step-bullet {
            width: $step-bullet-size;
            height: $step-bullet-size;
            transition: all 0.4s sizes.$easing-rubber;
            border-radius: $step-bullet-size;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: colors.$border-color;
            border: sizes.$border-width solid colors.$border-color;
            transform: scale(0.3);
            overflow: hidden;

            i {
                font-size: $step-icon-size;
                position: absolute;
                opacity: 0;

                transition: all 0.4s sizes.$easing-rubber;
            }

            .step-invalid {
                opacity: 1;
                font-size: 14px;
            }

            .bullet-icon-active {
                color: colors.$text-emphasis-color;
                transform: scale(0.3);
            }
            .bullet-icon-done {
                color: colors.$dark;
                transform: translateY(100%);
            }
        }

        &.active {
            .step-bullet {
                transform: scale(1);
                border-color: $active-color;
                background-color: transparent;

                .bullet-icon-active {
                    opacity: 1;
                    transform: scale(0.6);
                    color: $active-color;
                }
            }
            .steps-label {
                color: colors.$text-emphasis-color;
            }
        }

        &.done {
            .steps-label {
                color: colors.$text-muted-color;
            }
            .step-bullet {
                transform: scale(0.61);
                background-color: colors.$text-muted-color;
                border-color: colors.$text-muted-color;
                border-radius: 7px;
                .bullet-icon-done {
                    opacity: 1;
                    transform: translateY(0%);
                }

                .bullet-icon-active {
                    opacity: 0;
                    transform: scale(1.3);
                }
            }
        }

        &:hover:not(.active) {
            .steps-label {
                transition: none;
                color: colors.$text-emphasis-color;
            }
            .step-bullet {
                background-color: colors.$text-emphasis-color;
            }
        }
    }

    .steps-spacer {
        flex-grow: 1;
        border-bottom: sizes.$border-width solid colors.$border-color-light;
        margin: 0 sizes.$gutter-xs;
    }
}
