@use "sass:math";
@use "Content/scss/sizes";
@use "Content/scss/colors";

.progress-circle {
    flex-direction: column;
    padding-bottom: sizes.$gutter-sm;

    $state-indicator-size: 30px;
    $state-indicator-progress-radius: 13px;

    &.inline {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 0;

        .state-desc {
            margin-bottom: 0;
        }

        .state-indicator {
            margin-right: sizes.$gutter-xs;
            order: -1;
        }
    }

    &:not(.inline) {
        .state-desc {
            height: 25px;
        }
    }

    .state-desc {
        font-size: 9px;
        color: colors.$text-muted-var;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: sizes.$gutter-xxs;
        letter-spacing: 0.15em;
        display: flex;
        align-items: flex-end;
    }

    .state-indicator {
        height: $state-indicator-size;
        width: $state-indicator-size;
        border-radius: $state-indicator-size;
        color: colors.$panel-header-bg-var;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: colors.$text-muted-var;
        position: relative;

        i {
            font-size: 1.1rem;
            position: absolute;
            z-index: 10;
        }

        .progress-ring {
            display: none;
        }
    }

    &.success {
        .state-desc {
            color: colors.$text-muted-var;
        }

        .state-indicator {
            background-color: colors.$success-var;
        }
    }

    &.failed {
        .state-desc {
            color: colors.$text-muted-var;
        }

        .state-indicator {
            background-color: colors.$danger-var;
        }
    }

    &.warning {
        .state-desc {
            color: colors.$text-muted-var;
        }

        .state-indicator {
            background-color: colors.$warning-var;
        }
    }

    &.running {
        .state-desc {
            color: colors.$text-muted-var;
            display: flex;
            align-items: flex-end;

            strong {
                font-size: 14px;
                color: colors.$text-color-var;
                margin-right: sizes.$gutter-xxs;
            }
        }

        .state-indicator {
            background-color: colors.$well-bg-var;

            i {
                color: colors.$progress-var;
            }
        }

        .progress-ring {
            width: 100%;
            height: 100%;
            display: block;
            transform: scale(0.9);

            circle {
                cx: math.div($state-indicator-size, 2);
                cy: math.div($state-indicator-size, 2);
                r: $state-indicator-progress-radius;
                transition: stroke-dashoffset 0.35s;
                transform: rotate(-90deg);
                transform-origin: math.div($state-indicator-size, 2) math.div($state-indicator-size, 2);
                stroke-linecap: round;
                stroke-width: 4;
                fill: transparent;
                stroke: colors.$progress-var;
                stroke-dasharray: 81.68 81.68; // 2 * pi * r / r = 13
                //stroke-dashoffset: 30; // offset = circumference - percent / 100 * circumference
            }
        }
    }
}
