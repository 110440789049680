@use "Content/scss/colors";
@use "Content/scss/sizes";

.sample-data {
    flex-grow: 1;
    position: relative;
    background-position: center;
    overflow: hidden;

    .sample-data-bg {
        position: absolute;
        width: 100%;
        min-width: 1600px;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        pointer-events: none;

        .st0 {
            fill: #7f6c4c;
        }
        .st1 {
            fill: #ed9c60;
        }
        .smoke-color {
            fill: var(--panel-bg-1);
            opacity: 0.66;
        }
        .smoke-color-2 {
            fill: var(--panel-bg-2);
            opacity: 0.85;
        }
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        min-width: 1600px;
        background-repeat: no-repeat;
        background-image: url("../../../../../../wwwroot/Content/img/documents.svg");
        left: 50%;
        transform: translateX(-50%);
        background-clip: content-box;
        top: 0;
        background-size: 100% auto;
        z-index: 2;
        background-position: top;
        pointer-events: none;
    }

    .scroll {
        overflow: auto;
        height: 100%;
        z-index: 999;
        display: block;
        position: relative;
    }

    .center {
        margin: auto;
        min-height: 100%;
        padding-top: sizes.$gutter;
        padding-bottom: sizes.$gutter;
        max-width: 800px;
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            max-width: 40em;
        }

        .sample-code {
            background-color: var(--panel-bg-2);
            border-radius: sizes.$gutter-sm;

            .sample-code-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: sizes.$gutter-sm;
                padding: 0;
                margin-bottom: sizes.$gutter;

                h3 {
                    margin: 0;
                }
            }
        }
    }
}
