@use "Content/scss/colors";
@use "Content/scss/sizes";

.change-vector-popover {
    .change-vector-grid {
        display: grid;
        grid-template-columns: 70px auto;
        gap: sizes.$gutter-xs;
        &:not(:first-of-type) {
            margin-top: sizes.$gutter-xs;
        }
    }

    .change-vector-item,
    .change-vector-label {
        overflow-wrap: anywhere;
        line-height: 1rem;
    }
}
.connected-clients-section {
    .connected-clients-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, max-content));

        .connected-client {
            background: var(--panel-bg-2);
            border-radius: sizes.$gutter-xs;
            font-size: 12px;
            margin: sizes.$gutter-xs * 0.5;
            &:first-of-type {
                margin-left: 0;
            }
            div + div {
                border-top: 1px solid var(--border-color-light);
            }
        }
    }
}

.destination-modal {
    .task-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: sizes.$gutter-sm;
        border-radius: sizes.$gutter-xxs;
        height: 100%;
        text-align: center;
        position: relative;

        h4 {
            color: inherit;
        }

        .license-restricted-badge {
            position: absolute;
            top: sizes.$gutter-xxs;
            right: sizes.$gutter-xxs;
        }

        .counter-badge {
            position: absolute;
            top: sizes.$gutter-xxs;
            left: sizes.$gutter-xxs;
        }

        i {
            font-size: 36px;
            line-height: 1em;
        }
        transition:
            background-color 0.5s sizes.$global-easing,
            color 0.1s !important;

        &:hover {
            transition:
                background-color 0.05s sizes.$global-easing,
                color 0.05s !important;
            color: colors.$white;
        }
    }

    @each $name, $color in colors.$task-colors {
        .#{$name} {
            background-color: fade-out($color, 0.9);
            color: $color;

            &:hover {
                background-color: fade-out($color, 0.1);
            }
        }
    }
}
